import { InputField } from "../form"
import { Button } from "../button"
import { Typography } from "../typography"
import { ROUTE_PATHS } from "../../constants"

export const TermsAndCoditionLink = ({ register, errors, disabled }) => {
  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center">
        <InputField
          register={{...register('agreedToTerms')}}
          disabled={disabled}
          type="checkbox"
          className="mr-2"
        />
         <Typography
          translationKey="VENDOR.CONTACT_FORM.TERMS"
          variant="label"
          values={{ buttonLink: <Button
            variant="link"
            className="inline"
            translationKey="VENDOR.CONTACT_FORM.TERMS_TEXT"
            onClick={e => {
              e.preventDefault()
              window.open('/'+ROUTE_PATHS.TERMS, '_blank', 'rel=noopener noreferrer')
            }}
            /> }}   
          className='w-full'
        />
    </div>
    <span className="text-sm text-red-300 mt-2" >{errors?.agreedToTerms?.message}</span>
    </div>
  )
}

